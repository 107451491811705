/* ------------------------------------------------------------------------------------------------ */
/* Responsive */
/* ------------------------------------------------------------------------------------------------ */
@media (max-width: 767px) { .header { padding-top: 24px; }
  .header .header_top .col-md-3 { text-align: center; }
  .header .header_logo { margin-bottom: 16px; }
  .header .header_logo img { max-height: 40px; }
  .header .header_search { position: absolute; top: -70px; left: -100%; width: 100%; height: 100vh; background: #FFF; display: flex; align-items: center; justify-content: center; padding: 20px; z-index: 9; }
  .header .header_right { padding-right: 50px; }
  .header .header_menu { position: absolute; top: -100vh; left: 0; background: #000000; width: 100%; height: 100vh; overflow: auto; padding: 20px; transition: all .3s ease-in-out; }
  .header .header_menu .menu-wrap { display: flex; flex-direction: column; text-align: left; align-items: flex-start; }
  .header .header_menu .menu-wrap li.has-sub .submenu { position: relative; padding: 0; left: 0; background: transparent; }
  .header .header_menu .menu-wrap li.has-sub:hover .submenu { top: 100%; opacity: 0; visibility: hidden; max-height: 0; }
  .header .header_menu .menu-wrap li.has-sub.sub-open .submenu { top: 100%; opacity: 1; visibility: visible; max-height: max-content; padding: 6px 10px; }
  .header.sticky .header_top { margin-top: 0; }
  .header.sticky .header_right { display: none; }
  .header.sticky .header_logo { position: relative; top: 0; margin-bottom: 0; }
  .header.sticky .mobile-menu { top: 18px; }
  .form-action .btn { display: block; width: 100%; }
  .form-action .btn + .btn { margin-left: 0; margin-top: 16px; }
  .privatevault .privatevault_description-wrap .row .col-md-6 { margin-bottom: 24px; }
  .cart_total .agreement { margin-bottom: 8px; }
  .cart_total .grand_total .total_qty { margin-bottom: 8px; }
  .upImg-group .col { flex: 0 0 calc(100% / 3); }
  .upload-file .add-photo { padding-top: 100%; } }

.mobile-menu { display: none; }

@media (max-width: 767px) { .mobile-menu { z-index: 3; display: block; position: absolute; width: 27px; height: 22px; top: 24px; right: 16px; }
  .mobile-menu span { display: block; position: absolute; height: 3px; width: 100%; left: 0; background: #FFF; opacity: 1; transform: none; trnasfrom-origin: center; transition: all .4s ease; }
  .mobile-menu span:nth-child(1) { top: 0; }
  .mobile-menu span:nth-child(2) { top: 8px; }
  .mobile-menu span:nth-child(3) { top: 16px; } }

body.menu-open .mobile-menu span { background: #fff; }

body.menu-open .mobile-menu span:nth-child(1) { top: 6px; transform: rotate(-135deg); }

body.menu-open .mobile-menu span:nth-child(2) { top: 6px; opacity: 0; }

body.menu-open .mobile-menu span:nth-child(3) { top: 6px; transform: rotate(135deg); }

body.menu-open .header_menu { top: 0; }